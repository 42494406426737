body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root,
html {
  background-color: black;
  color: white;
}

@media screen and (min-width: 900px) {

  .card-grow {
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .card-grow:hover {
    transform: scale(1.03);
    cursor: pointer;
  }
}